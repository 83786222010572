import {shallowEqual, useSelector} from 'react-redux';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {CircularProgress, Box, Stack} from '@mui/material';
import {strings} from '@app/common/strings';
import {CSSProperties} from 'react';

const headerContainer: CSSProperties = {
  fontFamily: 'Balsamiq-Sans, serif',
  letterSpacing: '0.01px',
  marginTop: 10,
  maxHeight: 450,
  overflow: 'auto',
};

const tableHeader: CSSProperties = {
  border: '1px solid black',
  textAlign: 'center',
  fontSize: 12,
  width: 'fit-content',
  fontWeight: 'bold',
};

const tableRowSection: CSSProperties = {
  border: '1px solid black',
  borderCollapse: 'collapse',
  fontSize: 12,
  textAlign: 'center',
};

export const tableRowKeys = [
  'userid',
  'sapCode',
  'designationId',
  'designationName',
  'staffName',
  'expenseMonth',
  'expenseYear',
  'divisionId',
  'divisionName',
  'hqId',
  'hqName',
  'status',
  'submitteddate',
  'lastmodifieddate',
];

const ExpenseStatusReport = () => {
  const data = useSelector(
    expenseReportsStateSelector.getStatusReportData(),
    shallowEqual,
  );
  const TALoading = useSelector(
    expenseReportsStateSelector.getTALoading(),
    shallowEqual,
  );

  const docHeader = [
    'User ID',
    'SapCode',
    'Designation Id',
    'Designation',
    'Staff Name',
    'Expense Month',
    'Expense Year',
    'Division Id',
    'Division',
    'hqId',
    'HQ',
    'Status',
    'Submit date',
    'Modified date',
  ];

  if (TALoading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }
  if (data?.row?.length === 0 || !data?.row) {
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        padding={30}>
        {strings.ModuleSpecificUtilitie.NoRecordsFound}
      </Stack>
    );
  }

  const formattedDateHandle = (row: any) => {
    if (row != null) {
      let formattedDate = row.replace('T', ' ').replace(/:\d{2}$/, '');
      return formattedDate;
    } else {
      return 'NA';
    }
  };

  return (
    <div style={headerContainer}>
      <header>
        <h3 style={{textAlign: 'center', marginRight: 100}}>
          {
            strings.ModuleSpecificUtilitie.expenseReportPage
              .MONTHWISEEXPENSESTATUSREPORT
          }
        </h3>
        <div style={{width: '100%'}}>
          <div>
            <span style={{fontWeight: 'bold', width: '100%'}}>Division: </span>
            <span>{data?.header?.divName}</span>
          </div>
          <div>
            <span style={{fontWeight: 'bold', width: '100%'}}>Period: </span>
            <span>{data?.header?.period}</span>
          </div>
        </div>
      </header>
      <div style={{width: '100%', marginTop: '2%'}}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              {docHeader?.map((val: string) => (
                <th key={val} style={tableHeader}>
                  {val}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.row?.map((tableRow: any, index: number) => (
              <tr key={tableRow}>
                {tableRowKeys.map((row: any) => (
                  <td
                    key={row}
                    style={{
                      ...tableRowSection,
                      fontWeight:
                        tableRow.section !== 'Row' ? 'bold' : 'normal',
                    }}>
                    {(() => {
                      if (
                        row === 'id' &&
                        (tableRow.section === 'Total' ||
                          tableRow.section === 'G. Total')
                      ) {
                        return null;
                      } else if (row === 'month' && tableRow.month === null) {
                        return tableRow.section;
                      } else if (
                        row === 'submitteddate' ||
                        row === 'lastmodifieddate'
                      ) {
                        return formattedDateHandle(tableRow[row]);
                      } else {
                        return tableRow[row];
                      }
                    })()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExpenseStatusReport;
